import classNames from "classnames";
import * as React from "react";
import { VideoOverlay } from "../../components/VideoOverlay";
import "./ProgramSchedule.scss";

interface IProgramExpanderProps {
  title: string;
  children: React.ReactNode;
}

export const ProgramExpander: React.FunctionComponent<IProgramExpanderProps> = (
  props
) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <div className={classNames("ProgramExpander", { expanded })}>
      <div className="HeaderBar" onClick={() => setExpanded(!expanded)}>
        <span className="title">{props.title}</span>
        <div className={classNames("Expander", { expanded })} />
      </div>
      <div className={classNames("Body", { expanded })}>{props.children}</div>
    </div>
  );
};

interface IProgramScheduleProps {}

export const ProgramSchedule: React.FunctionComponent<IProgramScheduleProps> = (
  props
) => {
  const [video, setVideo] = React.useState("");
  const AUSTIN_PROPER_HOTEL = "https://www.google.com/maps/place/Austin+Proper+Hotel/@30.2662104,-97.7500515,17z/data=!3m1!4b1!4m10!3m9!1s0x8644b5e46ca2874b:0xc3204178fe805fc8!5m3!1s2023-04-16!4m1!1i2!8m2!3d30.2662104!4d-97.7500515!16s%2Fg%2F11gmv2j0ys?hl=en-US";
  const SALT_LICK_BBQ = "https://www.google.com/maps/place/The+Salt+Lick+BBQ/@30.131725,-98.013332,9z/data=!4m5!3m4!1s0x0:0x57cbce046a53e6b!8m2!3d30.1317253!4d-98.0133325?ll=30.131725,-98.013332&z=9&t=m&hl=en-US&gl=US&mapclient=embed&cid=395398538745691755&coh=164777&entry=tt&shorturl=1";
  const MOONSHINE_GRILL = "https://www.google.com/maps/place/Moonshine+Grill/@30.2391858,-97.7673661,12.79z/data=!4m5!3m4!1s0x0:0x3d1cb3bee44dfaf4!8m2!3d30.2637566!4d-97.7380271?coh=164777&entry=tt&shorturl=1https://www.google.com/maps/place/Moonshine+Grill/@30.2391858,-97.7673661,12.79z/data=!4m5!3m4!1s0x0:0x3d1cb3bee44dfaf4!8m2!3d30.2637566!4d-97.7380271?coh=164777&entry=tt&shorturl=1";

  return (
    <div
      className={classNames("ProgramSchedule", {
        showingVideo: Boolean(video),
      })}
    >
      <h2>
        Schedule
      </h2>
      <div className="Expanders">

        <ProgramExpander title="Monday, April 3">
          <ul>
            <li>
              <div>
                <strong>Welcome reception with dinner and drinks at <a href={AUSTIN_PROPER_HOTEL}>Austin Proper Hotel</a></strong>
              </div>
              <div>
                Time: 6:00 pm CST
              </div>
            </li>
          </ul>
        </ProgramExpander>

        <ProgramExpander title="Tuesday, April 4">
          <ul>
            <li>
              <div>
                <strong>Breakfast at <a href={AUSTIN_PROPER_HOTEL}>Austin Proper Hotel</a></strong>
              </div>
              <div>
                Time: 8:00 am CST
              </div>
            </li>
            <li>
              <div>
                <strong>Classroom sessions at <a href={AUSTIN_PROPER_HOTEL}>Austin Proper Hotel</a></strong>
              </div>
              <div>
                Time: 9:00 am—4:00 pm CST
              </div>
            </li>
            <li>
              <div>
                <strong>Dinner and drinks at <a href={SALT_LICK_BBQ}>Salt Lick BBQ</a></strong>
              </div>
              <div>
                Time: 6:00 pm CST
              </div>
            </li>
          </ul>
        </ProgramExpander>

        <ProgramExpander title="Wednesday, April 5">
          <ul>
            <li>
              <div>
                <strong>Breakfast at <a href={AUSTIN_PROPER_HOTEL}>Austin Proper Hotel</a></strong>
              </div>
              <div>
                Time: 8:00 am CST
              </div>
            </li>
            <li>
              <div>
                <strong>Test Farm to see Deere’s tech up close</strong>
              </div>
              <div>
                Time: 9:00 am—3:00 pm CST
              </div>
            </li>
            <li>
              <div>
                <strong>Dinner at <a href={MOONSHINE_GRILL}>Moonshine Grill</a></strong>
              </div>
              <div>
                Time: 6:00 pm CST
              </div>
            </li>
          </ul>
        </ProgramExpander>
      </div>
      {video && (
        <VideoOverlay
          src={video}
          show={Boolean(video)}
          onOverlayClicked={() => setVideo("")}
        />
      )}
    </div>
  );
};
