import React from "react";
import "./App.scss";
import { Route, Routes } from "react-router-dom";
import { MeetTheExperts } from "./pages/experts/MeetTheExperts";
import { ModalProvider } from "./components/modal/Modal";
import { MediaResources } from "./pages/media/MediaResources";
// import { usePageTracking } from "./util/use-page-tracking";
import { HomePageComponent } from "./pages/home/TechSummit2023";
import { Header } from "./components/Header";

function App() {
  // usePageTracking();
//
  return (
    <ModalProvider>
      <Header />
      <Routes>
        <Route path="/" element={<HomePageComponent />} />
        <Route path="/experts" element={<MeetTheExperts />} />
        <Route
          path="/media/"
          element={<MediaResources key={Math.random()} />}
        />
      </Routes>
    </ModalProvider>
  );
}

export default App;
