import "./MediaResources.scss";

import * as React from "react";
import Footer from "../../components/Footer";
import { OutlineButton } from "../../components/OutlineButton";
import { useParams } from "react-router-dom";
import { VideoResources, VideoResourcesSection } from "./VideoResources";
import { ImageResources } from "./ImageResources";

export const urlRoot = "https://jd-tech-summit-2023.s3.amazonaws.com/media-resources";

export interface IImageResourcesProps {}

export type JdImageResource = string | { thumbnail: string; full: string };

export interface IMediaResourcesFooterProps {}

interface IMediaResourcesProps {}

export const MediaResources: React.FunctionComponent<IMediaResourcesProps> = (
  props
) => {
  const { section } = useParams<{ section: "" }>();
  const [tab, setTab] = React.useState<"images" | "video">("images");
  return (
    <div className="MediaResources">
      <div className="page-content">
        <h1>Resources</h1>
        <div className="tabs">
            <OutlineButton
              text="Images"
              onClick={() => setTab("images")}
              active={tab === "images"}
            />
            <OutlineButton
              text="Videos"
              onClick={() => setTab("video")}
              active={tab === "video"}
            />
        </div>
        {tab === "images" && <ImageResources />}
        {tab === "video" && (
          <VideoResources section={section as VideoResourcesSection} />
        )}
      </div>
      <Footer />
    </div>
  );
};
