import * as React from "react";
import { IExpertSlideProps } from "./MeetTheExperts";

export const EXPERTS_2022: IExpertSlideProps[] = [
  {
    name: "Jahmy Hindman",
    src: "JahmyHindman",
    title: "Chief Technology Officer",
    bio: "Jahmy Hindman, Ph.D., is the chief technology officer at John Deere, a position he has held since July 2020. He is responsible for building Deere’s “tech stack,” the company’s intuitive end-to-end equipment solution made up of hardware and devices, embedded software, connectivity, data platforms, and applications. He also leads the company’s Intelligent Solutions Group (ISG) and is responsible for the global network of technology/innovation centers and the shared engineering function. Jahmy has more than 20 years of advanced technology, artificial intelligence, product engineering, and manufacturing experience. He earned his bachelor’s degree in mechanical engineering from Iowa State University as well as master’s and doctoral degrees in mechanical engineering from the University of Saskatchewan.",
  },
  {
    name: "Deanna Kovar",
    src: "DeannaKovar",
    title: "Vice President of Production and Precision Ag Production Systems",
    bio: "Deanna Kovar is the vice president of Production and Precision Ag Production Systems at John Deere. With expertise in agronomy, economics, products, and technology, she leads a global team focused on identifying opportunities to unlock sustainable value for global corn, soy, small grains, cotton, and sugar customers. During more than 20 years with Deere, she has held many roles in sales, marketing, and customer support, and is very knowledgeable about bringing Precision Ag technology to the market. Deanna earned a bachelor’s degree in agricultural business management from the University of Wisconsin at Madison and a master’s in business administration from Loyola University in Chicago.",
  },
  {
    name: "Julian Sanchez",
    src: "JulianSanchez",
    title: "Director of Emerging Technologies",
    bio: "Julian Sanchez, Ph.D., is a John Deere Fellow and the director of emerging technologies in the Intelligent Solutions Group (ISG) at John Deere. His team focuses on researching, developing, and supporting the deployment of Deere’s next-generation technologies to improve customer profitability and sustainability. As a seasoned technologist, he has made significant contributions at John Deere including the establishment of UX capabilities and the early deployment of digital solutions in agriculture. Julian holds over 20 patents and has published dozens of peer-reviewed studies in the areas of aviation, agriculture, and medical devices. He earned his bachelor’s in industrial engineering and a master’s in industrial and systems engineering from Florida International University and his Ph.D. in human factors from Georgia Institute of Technology.",
  },

  {
    name: "Nancy Post",
    src: "NancyPost",
    title: "Vice President of Embedded Software and Solutions",
    bio: "Nancy Post is the vice president of embedded software and solutions at John Deere. She is responsible for leading the development and commercialization of core Precision Agriculture Technology solutions, including GNSS receivers, correction signals, displays, universal guidance systems, and telematics devices. Nancy is also responsible for the global electronics group, a team that develops software and hardware systems, architecture, infrastructure, and operating systems for the enterprise. In addition to her 25-plus years at Deere, she brings expertise and experience from positions at other manufacturing companies, including Rockwell International and Compressor Controls Corporation. Nancy earned a bachelor’s degree in computer science from Mount Mercy University and a master’s in business administration from the University of Phoenix.",
  },
  {
    name: "Jorge Heraud",
    src: "JorgeHeraud",
    title: "Vice President of Automation and Autonomy",
    bio: "Jorge Herald is the vice president of automation and autonomy within the Intelligent Solutions Group (ISG) at John Deere, where he leads the development efforts for products around automation and autonomy to make customers more productive, profitable, and sustainable. Prior to his career with John Deere, Jorge was the CEO and co-founder of Blue River Technology, which was acquired by Deere & Company in September 2017 and is now run as an independent subsidiary. He holds a bachelor’s in electrical engineering from Universidad Catolica del Peru and a master’s in electrical engineering and engineering management from Stanford University.",
  },
  {
    name: "Lane Arthur",
    src: "LaneArthur",
    title: "Vice President of Data, Applications, and Analytics",
    bio: "Lane Arthur, Ph.D., is the vice president of data, applications, and analytics for the Intelligent Solutions Group (ISG) at John Deere. He oversees the design, delivery, and support of information-enabled solutions that allow farmers, ag solutions providers, and construction owners to leverage data and develop insights to make operations more profitable. Lane is also focused on the creation and execution of the John Deere Operations Center™, a set of online and mobile tools that enable access to farm information anytime, anywhere. He is responsible for the company’s technology businesses in Europe and South America. Before Deere, Lane held leadership roles at DuPont Pioneer®, including vice president and chief information officer, in which he oversaw the deployment of global systems that applied analytical methods and modeling with large data sets to solve business problems. He earned a bachelor’s degree in biochemistry from the University of Georgia and a Ph.D. in genetics and development from Columbia University Vagelos College of Physicians and Surgeons.",
  },
  {
    name: "Jenny Ose",
    src: "JennyOse",
    title: "Director of Marketing and Shared Services",
    bio: "Jenny Ose is the director of marketing and shared services for the John Deere Ag & Turf Division. Together with her team, she focuses on transforming Deere’s business by building and delivering world-class marketing capabilities and enhanced go-to-market support for our dealers in the U.S. and Canada. Jenny has worked for John Deere for 25 years in a variety of roles and locations. She has experience in all Ag & Turf segments including turf, small ag, large ag, and multiple product and factory marketing positions throughout the U.S.; Zweibrücken, Germany; and South America. In addition, Jenny has worked in field sales and territory roles throughout the U.S. and Canada. She earned a bachelor’s degree from Augsburg University and a master’s in business administration from Kellogg School of Management at Northwestern University.",
  },
  {
    name: "Denver Caldwell",
    src: "DenverCaldwell",
    title: "Director of Ag & Turf Sales – U.S. and Canada",
    bio: "Denver Caldwell is the director of sales for U.S. and Canada at John Deere. He is responsible for leading and overseeing the sales strategy and lifecycle, bringing agriculture, turf, construction, and forestry technology and machinery to customers across both regions. Denver has extensive knowledge in both sales and marketing, holding integral roles in both during his 25 years at Deere. He is also an advocate for Deere’s LEAP Coalition, which seeks to equip Black farmers with legal and financial resources to ensure their longevity in the agricultural business. Denver earned a bachelor’s degree in management and a master’s degree in marketing from Oklahoma State University. ",
  },
  {
    name: "Than Hartsock",
    src: "ThanHartsock",
    title: "Global Director of Corn and Soy Production Systems",
    bio: "Than Hartsock is the global director of corn and soy production systems at John Deere. He is responsible for leading a team that creates solution road maps and product plans to unlock new value for customers within the soy and corn space. Than is an experienced sales and marketing professional, working across a range of Deere divisions to integrate precision technology into the company’s machine lineup. He earned a bachelor’s degree in crop science from Ohio State University, a master’s degree in soil science from the University of Kentucky, and a master’s degree in business administration from Northwestern University.",
  },
  {
    name: "Josh Hoffman",
    src: "JoshHoffman",
    title: "Director of User Experience & Design",
    bio: "Josh Hoffman is the director of user experience (UX) and design at John Deere, leading a team of UX and user interface (UI) designers and information technology (IT) personnel to create and implement customer products. He oversees all UX design activities across agriculture and construction, seamlessly integrating Deere’s “tech stack” into its machinery while ensuring products are simple to use. Through his 15-year career at John Deere, Josh has worked in UX development, UX research, design, human-automation interaction, and industrial design. He earned a bachelor’s degree and a master’s degree in industrial engineering with a focus in human factors from the University of Iowa.",
  },
  {
    name: "Jon Gilbeck",
    src: "JonGilbeck",
    title: "Global Construction Equipment Product Management and Electrification Lead",
    bio: "Jon Gilbeck is the construction equipment and electrification lead for the John Deere Construction & Forestry Division. In this role, he is responsible for a global team that covers product management and planning for all types of construction equipment, supporting customers’ profitability, sustainability, and safety efforts. During his 23 years at Deere, Jon has supported project management and marketing efforts across the company’s various divisions, utilizing his business-development expertise to enhance Deere’s offerings. He earned a bachelor’s degree in physics from University of Wisconsin-LaCrosse and a master’s degree in engineering management from Marquette University.",
  },
  {
    name: "Jonny Spendlove",
    src: "JonnySpendlove",
    title: "Sr. Product Manager of Connectivity",
    bio: "Jonny Spendlove is the senior product manager of connectivity at John Deere, overseeing the company’s efforts to bring global connectivity and connected machinery to customers globally. He leads a team of project managers working on connectivity, currently leading the RFP for Deere’s satellite communications partner to help the company achieve its goal of connecting 1.5-million machines by 2026. During his 11 years with Deere, Jonny has worked in sales, marketing, and product-development roles for both agriculture and construction equipment. He earned a bachelor’s degree in English language and literature from the University of Utah and a master’s degree in business administration from the University of Chicago.",
  },
  {
    name: "Oriana Lisker Bosin",
    src: "OrianaBosin",
    title: "Product Manager of Sustainability",
    bio: "Oriana Lisker Bosin is the group product manager of sustainability at John Deere, leading a team that develops products for environmental and social impact. In this role, she crafts and owns the vision, strategy, and road map to help farmers reduce their carbon emissions and increase their profitability. Oriana has extensive experience across a range of early-stage ventures in sustainability, digital ag, and large-scale sensor data, harnessing this knowledge to bring environmentally efficient practices to customers. She earned a bachelor’s degree in chemistry and computer science from Duke University and a master’s degree in agricultural economics and sustainability from the University of California, Davis.",
  },
  {
    name: "Jon Ebert",
    src: "JonEbert",
    title: "Manager of North American Public & Industry Relations",
    bio: (
      <>
        <div>Mobile: (913) 302-0592</div>
        <div>
          <a href="mailto:EbertJonathanP@JohnDeere.com">
            EbertJonathanP@JohnDeere.com
          </a>
        </div>
      </>
    ),
  },
  {
    name: "Franklin Peitz",
    src: "FranklinPeitz",
    title: "Technology and Innovation Public Relations Manager",
    bio: (
      <>
        <div>Mobile: (515) 776-7329</div>
        <div>
          <a href="mailto:PeitzFranklinJ@JohnDeere.com">
            PeitzFranklinJ@JohnDeere.com
          </a>
        </div>
      </>
    ),
  },
];
