import * as React from "react";
import classNames from "classnames";
import { ModalContext } from "../../components/modal/Modal";
import {
  IImageResourcesProps,
  JdImageResource,
  urlRoot,
} from "./MediaResources";

interface ImageResource {
  category: string;
  path: JdImageResource;
  label?: string;
}

export const ImageResources: React.FunctionComponent<IImageResourcesProps> = (
  props
) => {

  const [category, setCategory] = React.useState<
    "all" | "tractor" | "see-and-spray" | "presenters" | "ee" | "exactshot" | "event" | "x9"
  >("all");
  const { handleModal } = React.useContext(ModalContext);

  const imgList: ImageResource[] = [
    { category: "exactshot", path: "ES-1_1x.jpg"},
    { category: "exactshot", path: "ES-with-seeds-only_1x.jpg"},
    { category: "exactshot", path: "ES-with-seeds-and-plants_1x.jpg"},
    { category: "exactshot", path: "ES-only_1x.jpg"},
    { category: "exactshot", path: "ES-2_1x.jpg"},
    { category: "ee", path: "MicrosoftTeams-image_1x.jpg"},
    { category: "ee", path: "E145__00004_1x.jpg"},
    { category: "ee", path: "E145__00006_1x.jpg"},
    { category: "ee", path: "E145__00010_1x.jpg"},
    { category: "ee", path: "CHARGING_Stationary_1x.jpg"},
    { category: "see-and-spray", path: "r4f064278_rrd_1x.jpg" },
    { category: "see-and-spray", path: "r4f064314_VS_1x.jpg" },
    { category: "see-and-spray", path: "r4f064314_VS.jpg" },
    { category: "see-and-spray", path: "r4f064278_rrd.jpg" },
    { category: "tractor", path: "r4f167304_rrd_1x.jpg" },
    { category: "tractor", path: "r4f167378_rrd_1x.jpg" },
    { category: "tractor", path: "r4f167445_rrd_1x.jpg" },
    { category: "tractor", path: "r4f175043_rrd_1x.jpg" },
    { category: "tractor", path: "r4f175055_rrd_1x.jpg" },
    { category: "tractor", path: "r4f175064_rrd_1x.jpg" },
    { category: "tractor", path: "71_r4g048434_rrd.jpg" },
    { category: "tractor", path: "72_r4f064245_rrd.jpg" },
    { category: "tractor", path: "72_r4f064278_rrd.jpg" },
    { category: "tractor", path: "72_r4f064281_rrd.jpg" },
    { category: "tractor", path: "72_r4f064300_rrd.jpg" },
    { category: "tractor", path: "72_r4f064304_rrd.jpg" },
    { category: "tractor", path: "72_r4f064314_VS.jpg" },
    { category: "tractor", path: "72_r4f064323_rrd.jpg" },
    { category: "tractor", path: "72_r4f064332_rrd.jpg" },
    { category: "tractor", path: "72_r4f064337_rrd.jpg" },
    { category: "tractor", path: "72_r4f064350_rrd.jpg" },
    { category: "tractor", path: "72_r4f064366_rrd.jpg" },
    { category: "tractor", path: "r4f167299_rrd_@1x.jpg" },
    { category: "tractor", path: "r4f167301_rrd_@1x.jpg" },
    { category: "tractor", path: "r4f167312_rrd_@1x.jpg" },
    { category: "tractor", path: "r4f167334_rrd_@1x.jpg" },
    { category: "tractor", path: "r4f167365_rrd_@1x.jpg" },
    { category: "tractor", path: "r4f167384_rrd_@1x.jpg" },
    { category: "tractor", path: "r4f167410_rrd_@1x.jpg" },
    { category: "tractor", path: "r4f167426_rrd_@1x.jpg" },
    { category: "tractor", path: "r4f167433_rrd_@1x.jpg" },
    { category: "tractor", path: "r4f167436_rrd_@1x.jpg" },
    { category: "tractor", path: "r4f167438_rrd_@1x.jpg" },
    { category: "tractor", path: "r4f167447_rrd_@1x.jpg" },
    { category: "x9", path: "72_r4f086046_EC_rrd.jpg" },
    { category: "x9", path: "72_r4f086435_rrd_02.jpg" },
  ]
    .filter((x) => Boolean(x.category))
    .filter((x) => x.category === category || category === "all")


  function labelFor(x: { category: string; path: JdImageResource }) {
    // const path = typeof x.path === "string" ? x.path : x.path.full;

    return "View";
  }

  return (
    <div className="ImageResources">
      <div className="filters">
        <div
          onClick={() => setCategory("all")}
          className={classNames({ active: category === "all" })}
        >
          All
        </div>
        <div
          onClick={() => setCategory("tractor")}
          className={classNames({ active: category === "tractor" })}
        >
          Autonomous Tractor
        </div>
        <div
          onClick={() => setCategory("ee")}
          className={classNames({ active: category === "ee" })}
        >
          Electric Excavator
        </div>
        <div
          onClick={() => setCategory("exactshot")}
          className={classNames({ active: category === "exactshot" })}
        >
          ExactShot™
        </div>
        <div
          onClick={() => setCategory("see-and-spray")}
          className={classNames({ active: category === "see-and-spray" })}
        >
          See &amp; Spray™ Ultimate
        </div>
        <div
          onClick={() => setCategory("x9")}
          className={classNames({ active: category === "x9" })}
        >
          X9
        </div>
        {/* <div
          onClick={() => setCategory("presenters")}
          className={classNames({ active: category === "presenters" })}
        >
          Experts
        </div> */}
      </div>
      <div className={classNames("images-grid", category)}>
        {imgList.length === 0 && <div className="no-images">Coming Soon.</div>}
        {imgList.map((x) => (
          <div
            className="img-wrapper"
            key={typeof x.path === "string" ? x.path : x.path.full}
            onClick={() =>
              handleModal({
                lightbox: true,
                img: `${urlRoot}/images/${
                  typeof x.path === "string" ? x.path : x.path.full
                }`,
              })
            }
          >
            <div
              className={classNames("img-thumb", x.category)}
              style={{
                backgroundImage: `url(${urlRoot}/images/${
                  typeof x.path === "string" ? x.path : x.path.thumbnail
                })`,
              }}
            />
            <div className="thumb-overlay">
              <span>{labelFor(x)}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
