import * as React from "react";

import "./TechSummit2023.scss";
import classNames from "classnames";
import Footer from "../../components/Footer";
import { ReactComponent as Logo } from "./img/TechSummit_Hero-Text.svg";
import { ReactComponent as AustinTXLogo } from "./img/TechSummit_Lineart.svg";
import { ProgramSchedule } from "./ProgramSchedule";

interface TechSummit2023Props {}

export const TeaserHero: React.FunctionComponent = () => {
  return (
    <div className="TeaserHero">
      <video className="TeaserHero__video" autoPlay muted loop playsInline poster="/img/tech-summit-hero-placeholder.jpeg">
        <source
          src="https://jd-tech-summit-dev.s3.amazonaws.com/media-resources/TechSummit-Header.mp4"
          type="video/mp4"
        />
      </video>
      <div className="TeaserHero__content">
        <Logo className="RealLogo" />
      </div>
    </div>
  );
};

export const YellowHr: React.FunctionComponent = () => {
    return (
      <PadPanel>
        <div className="YellowHr"></div>
      </PadPanel>
    );
  };

export const PipeJoined: React.FunctionComponent<{ parts: string[] }> = (
  props
) => {
  return (
    <span className="PipeJoined">
      {props.parts.map((part, i) => (
        <span key={i}>
          {part}
          {i < props.parts.length - 1 ? (
            <span className="PipeJoined__pipe"></span>
          ) : null}
        </span>
      ))}
    </span>
  );
};

export const KeynoteCallout: React.FunctionComponent = () => {
  return (
    <div className="KeynoteCallout">
      <div className="KeynoteCallout__logo">
        <AustinTXLogo className="line-art"></AustinTXLogo>
      </div>
      <div className="KeynoteCallout__content">
        <h2 className="KeynoteCallout__title">We're so excited <span className="nobr">you're here.</span></h2>
        <PadPanel>
          <p>
            The Summit will officially begin at 6:00 pm CST on Monday, April 3, and end the evening of Wednesday, April 5.
          </p>
          <p>
            Please reach out to the PR team onsite if you have any questions during your trip. We hope you enjoy everything you hear, see, and experience over the next few days.
          </p>
        </PadPanel>
      </div>
    </div>
  );
};

export const PadPanel: React.FunctionComponent<{
  children: React.ReactNode;
}> = (props) => {
  return <div className="PadPanel">{props.children}</div>;
};

export const HomePageComponent: React.FunctionComponent<TechSummit2023Props> = (
  props
) => {
  return (
    <div className={classNames("TechSummit2023")}>
      <TeaserHero />
      <PadPanel>
        <YellowHr />
        <PadPanel>
          <div className="page-intro">Welcome</div>
        </PadPanel>
      </PadPanel>
      <KeynoteCallout></KeynoteCallout>
      <YellowHr />
      <PadPanel>
        <ProgramSchedule />
      </PadPanel>
      <Footer />
    </div>
  );
};
