import * as React from "react";
import classNames from "classnames";
import { ModalContext } from "../../components/modal/Modal";
import { urlRoot } from "./MediaResources";

export type VideoResourcesSection = "all" | "booth" | "tractor";

export interface IVideoResourcesProps {
  section: VideoResourcesSection;
}

export const VideoResources: React.FunctionComponent<IVideoResourcesProps> = (
  props
) => {
  const [category, setCategory] = React.useState<"all" | "booth" | "event" | "field" | "tractor" | "keynote" | "exactshot" | "see-and-spray" | "vr">("all");
  const { handleModal } = React.useContext(ModalContext);

  const vidList: {
    category: string;
    path: string;
  }[] = [
    { category: "all", path: "protect-the-land" },
    { category: "all", path: "cutting-edge-tech" },
    { category: "tractor", path: "VideoOne_Cab" },
    { category: "tractor", path: "VideoTwo_Drone" },
    { category: "tractor", path: "VideoFour_DroneThree" },
    { category: "tractor", path: "VideoFive_Front" },
    { category: "tractor", path: "VideoSix_Night" },
    { category: "tractor", path: "VideoSeven" },
    { category: "tractor", path: "VideoEight_WorkingInField" },
    { category: "tractor", path: "05010_Experience_Farmer_3-Screen_v010" },
    { category: "exactshot", path: "ExactShot_SloMo1.1_1080" },
    { category: "exactshot", path: "ExactShot_SloMo1.2_1080" },
    { category: "event", path: "Feeding_the_World_John_Deere_Precision_Ag" },
    { category: "see-and-spray", path: "SnS_LauunchIntro" },
    { category: "see-and-spray", path: "SaSUBRoll01" },
    { category: "see-and-spray", path: "SaSUBRoll02" },
    { category: "see-and-spray", path: "SaSUBRoll03" },
    { category: "field", path: "76370_190513_GEP_8RX370_1775NT_Working_Aerial_DJI_0002_004" },
    { category: "field", path: "76370_190513_GEP_8RX_1775NT_Planter_working_A015_C005_05134A_003" },
    { category: "field", path: "76370_190513_GEP_8RX_1775NT_Planter_working_A015_C006_0513XV_006" },
    { category: "field", path: "76370_190513_GEP_8RX_1775NT_Planter_working_A015_C028_05140O" },
    { category: "field", path: "78761_06_77373_190828_GEP_combine_X950_HD50F_working_P1024629" },
    { category: "field", path: "78761_11_77373_190906_GEP_combine_X950_Wheat_aerial full unload on the go and running in field_DJI_0004" },
    { category: "field", path: "78761_12_77373_190906_GEP_combine_X950_Wheat_aerial machine running in field and unloading_DJI_0032" },
    { category: "field", path: "78761_14_77373_190906_GEP_combine_X950_Wheat_aerial machine running in field_DJI_0003_1" },
    { category: "field", path: "78761_32_77449_191007_GEP_AERIAL_X950_COMBINE_Harvesting_DJI_0024" },
    { category: "field", path: "78761_37_77449_191007_GEP_ronin_X950_8R_harvesting_P1366560" },
    { category: "field", path: "80395_200921_SJP_X9_1100_HD50__Day3C_DJI_0013" },
    { category: "field", path: "80395_200921_SJP_X9_1100_HDR_HDF_50_DJI_0013_1" },
    { category: "field", path: "80395_200921_SJP_X9_1100_S790_HDR_HDF_50_Day2_DJI_0017" },
    { category: "vr", path: "John Deere Build December 29, 2020 - Clip 01 - Introduciton" },
    { category: "vr", path: "John Deere Build December 29, 2020 - Clip 02 - Planting Slow Motion" },
    { category: "vr", path: "John Deere Build December 29, 2020 - Clip 04 - Interior Cab" },
  ]
  .filter((x) => Boolean(x.category)).filter((x) => (x.category === category) || (category === "all"))

  return (
    <div className="VideoResources">
      <div className="filters">
        <div
          onClick={() => setCategory("all")}
          className={classNames({ active: category === "all" })}
        >
          All
        </div>
        <div
          onClick={() => setCategory("tractor")}
          className={classNames({ active: category === "tractor" })}
        >
          Autonomous Tractor
        </div>
        <div
          onClick={() => setCategory("field")}
          className={classNames({ active: category === "field" })}
        >
          Field
        </div>
        <div
          onClick={() => setCategory("exactshot")}
          className={classNames({ active: category === "exactshot" })}
        >
          ExactShot™
        </div>
        <div
          onClick={() => setCategory("see-and-spray")}
          className={classNames({ active: category === "see-and-spray" })}
        >
          See &amp; Spray™ Ultimate
        </div>
        <div
          onClick={() => setCategory("vr")}
          className={classNames({ active: category === "vr" })}
        >
          VR
        </div>
      </div>
      {vidList.length === 0 && <div className="no-images">Coming Soon.</div>}
      {vidList.map((x) => (
        <div
          className="img-wrapper"
          key={x.path}
          onClick={() => handleModal({
            lightbox: true,
            video: `${urlRoot}/videos/${x.path.replaceAll(" ", "%20")}.mp4`,
          })}
        >
          <div
            className="img-thumb"
            style={{
              backgroundImage: `url(${urlRoot}/videos/thumb/${x.path.replaceAll(
                " ",
                "%20"
              )}.jpg)`,
            }} />
          <div className="thumb-overlay">
            <span>View</span>
          </div>
        </div>
      ))}
    </div>
  );
};
